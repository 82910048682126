@font-face {
  font-family: "Poppins";
  src: local("Poppins"), url("./fonts/poppins/Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Poppins-Thin";
  src: local("Poppins"), url("./fonts/poppins/Poppins-Thin.ttf") format("truetype");
}

@font-face {
  font-family: "Just Me Again Down Here";
  src: local("Just Me Again Down Here"), url("./fonts/JustMeAgainDownHere-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Kolker Brush";
  src: local("Kolker Brush"), url("./fonts/KolkerBrush-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "La Belle Aurore";
  src: local("La Belle Aurore"), url("./fonts/LaBelleAurore-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Morguns";
  src: local("Morguns"), url("./fonts/Morguns-Condensed.ttf") format("truetype");
}

html {
  background-color: black;
}

/* width */
::-webkit-scrollbar {
  width: 8px;
  border: 8px solid transparent;
  border-radius: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: "transparent";
  width: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  width: 10px !important;
  border-radius: 8px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}


.chakra-radio__control {
  border-color: #323D61 !important;
}
.chakra-radio__control[data-checked] {
  background: unset !important;
  background-color: black !important;
}

.chakra-switch__thumb {
 background:  #7BFF66 !important;
}

body {
  background-color: white !important
}

option {
  background: white !important;
  color: #323D61 !important;
}
select {
  color: #323D61 !important;
}

/* This should be the date input */
[name*="date"][aria-haspopup="dialog"] {
  border-color: black !important;
  color: black !important
}

.bouncing-loader {
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  width: 24px;
  height: 24px;
  margin: 3px 6px;
  border-radius: 50%;
  background-color: #a3a1a1;
  opacity: 1;
  animation: bouncing-loader 0.6s infinite alternate;
}

@media only screen and (max-width: 991px) {
  .bouncing-loader > div {
    width: 12px;
    height: 12px;
    margin: 1px 4px;
  }
}

@keyframes bouncing-loader {
  to {
    opacity: 0.1;
    transform: translateY(-16px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}